export const preventFormSubmit = (e) => {
    e.preventDefault();
    return false;
};

export const getEnv = () => {
    return {
        isLocal: process.env.REACT_APP_ENV === "local",
        isDev: process.env.REACT_APP_ENV === "development",
        isProd: process.env.REACT_APP_ENV === "production",
        isQA: process.env.REACT_APP_ENV === "qa",
    };
};

export const isTestEnv = () => {
    const { isDev, isQA, isLocal } = getEnv();
    return isDev || isQA || isLocal;
};

export const teamIdToNameMap = {
    108: "Los Angeles Angels",
    109: "Arizona Diamondbacks",
    110: "Baltimore Orioles",
    111: "Boston Red Sox",
    112: "Chicago Cubs",
    113: "Cincinnati Reds",
    114: "Cleveland Guardians",
    115: "Colorado Rockies",
    116: "Detroit Tigers",
    117: "Houston Astros",
    118: "Kansas City Royals",
    119: "Los Angeles Dodgers",
    120: "Washington Nationals",
    121: "New York Mets",
    133: "Athletics",
    134: "Pittsburgh Pirates",
    135: "San Diego Padres",
    136: "Seattle Mariners",
    137: "San Francisco Giants",
    138: "St. Louis Cardinals",
    139: "Tampa Bay Rays",
    140: "Texas Rangers",
    141: "Toronto Blue Jays",
    142: "Minnesota Twins",
    143: "Philadelphia Phillies",
    144: "Atlanta Braves",
    145: "Chicago White Sox",
    146: "Miami Marlins",
    147: "New York Yankees",
    158: "Milwaukee Brewers",
};

/**
 * @description Get a team's name from it's team id
 * @param {number} teamId team id  (e.g. 147)
 * @returns {string} team name
 */
export const getTeamNameById = (teamId) => {
    return teamIdToNameMap[teamId];
};

const BASE_URL =
    "https://statsapi.mlb.com/api/v1/teams?sportIds=1,11,12,13,14&hydrate=league";

export const fetchAllTeams = async () => {
    const response = await fetch(BASE_URL);
    const data = await response.json();

    return parseTeamData(data);
};

const parseTeamData = (data) => {
    return data?.teams?.map((team) => {
        const { id, name, teamName } = team || {};
        return {
            id,
            name,
            teamName,
        };
    });
};
